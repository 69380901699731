@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Georama:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

.noscroll::-webkit-scrollbar {
  display: none;
}
a {
  text-decoration: none;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: 200;

  font-family: inherit;
}
.sans {
  font-family: "Josefin Sans", sans-serif !important;
}
.poppins {
  font-family: "Poppins", sans-serif !important;
}
.lato {
  font-family: "Lato", serif !important;
}
.lora {
  font-family: "Lora", serif !important;
}
.ubuntu {
  font-family: "Ubuntu", sans-serif;
}
.noscroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
:root {
  --primary: #0d2c4a;
  --white: #ffffff;
  --bg: #f3f3f3;
  --orange: #d57006;
  --blue: #2672a3;
  --gray01: #ebebeb;
  --gray02: #878787;
  --orange01: #db6a04;
}
.flex {
  display: flex;
}
.btn {
  padding: 10px 12px;
  background-color: var(--blue);
  color: white;
  border-radius: 5px;
  outline: none;
  border: none;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 0.75em;
  cursor: pointer;
}
.btn-outline {
  background-color: transparent;
  border: 1px solid var(--blue);
  color: var(--blue);
}

.btn-orange {
  border-color: var(--orange01);
  background-color: var(--orange01);
}
@media (min-width: 760px) {
  .mobile {
    display: none !important;
  }
  .container {
    padding-right: 8%;
    padding-left: 8%;
  }
}
@media (max-width: 760px) {
  .desktop {
    display: none !important;
  }
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media (min-width: 1100px) {
  .container {
    /* padding-right: 12%;
    padding-left: 12%; */
  }
}
