header {
  width: 100%;
  height: 60px;
  background-color: white;
  box-shadow: 0px 4px 40px rgba(51, 51, 51, 0.08);
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header img {
  height: 60px;
  cursor: pointer;
  object-fit: contain;
}
header ul {
  display: flex;
  list-style: none;
}

header ul li {
  font-weight: 500;
  padding: 0 10px;
  cursor: pointer;
  font-family: "Lato";
  color: var(--black);
}
header ul li.active {
  font-weight: 900;
}
.logo {
  cursor: pointer;
}
.name {
  font-weight: 600;
  color: var(--primary);
  margin-left: 60px;
  display: none;
}
