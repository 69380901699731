.hpage {
  background-color: var(--bg);
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
.hcontent {
  width: 100vw;
  height: calc(100vh - 0px);
  overflow-y: auto;
}
.fsubmit {
  border-radius: 0;
  margin: unset !important;
  padding: 10px 30px !important;
  width: fit-content !important;
  display: block;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.check {
  align-items: center;
  cursor: pointer;
}
.check label {
  color: #878787;
  font-size: 0.9em;
  font-weight: 400;
}
.check input {
  margin-right: 10px;
}
.foption .check,
.pdetails .check {
  margin-right: 20px;
  margin-top: 10px;
}
.foption {
  margin-top: 30px;
}
.pdetails {
  margin-top: 30px;
}
.stop {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--orange);
  position: absolute;
  z-index: 10000;
  bottom: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 760px) {
  .hcontent {
    height: 100vh;
  }
  .mkl {
    flex-direction: column;
  }
  .mkl .input,
  .mbm {
    width: 100% !important;
  }
  .mbm {
    margin-top: 30px;
  }
  .foption .ops {
    flex-direction: column;
  }
  .pdetails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .pdetails .lbtx,
  .foption .lbtx {
    font-size: 1.3em;
    text-align: center;
  }
}
