.card {
  background-color: var(--white);
  padding: 5% 3%;
  border-radius: 25px;
  margin: 5% 0;
}

.card h2,
.stitle {
  text-align: center;
  color: var(--blue);
  font-weight: 900;
  margin-bottom: 15px;
}
.cdc {
  margin-bottom: -3% !important;
}
.card p {
  margin-top: 10px;
  color: var(--gray02);
  font-weight: 400;
  margin-bottom: 10px;
  color: #807979;
  line-height: 26px;
}
.card a {
  color: var(--orange01);
  font-weight: 400;
}
.card a:hover {
  text-decoration: underline;
}

.card ul {
  list-style: none;
  padding: 8%;
}

.card ul li {
  display: flex;
}
.card ul li img {
  width: 20px;
  margin-right: 10px;
}
.card span.nt {
  font-weight: 600;
  font-size: 0.7em;
  color: var(--gray02);
}
.cd img {
  width: 80px;
  margin-bottom: 15px;
}
.cd {
  width: 48%;
  border: 1px solid #8383831a;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
}
.cd p {
  color: #807979;
  line-height: 25px;
  font-size: 0.9em;
}
.cd h2 {
  text-align: left;
  font-size: 1.2em;
  color: #000000;
  margin-bottom: 0px;
}
.cds {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.btn_st {
  padding: 15px 45px;
  background-color: var(--orange);
  width: fit-content;
  color: white;
  font-weight: 600;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  cursor: pointer;
}
.logos {
  justify-content: flex-end;
}
.logos img {
  height: 20px;
  cursor: pointer;
  margin-left: 20px;
}
.dis_c {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  width: 25%;
}
.dis_c img {
  height: 40px;
}
.dis_c span {
  display: block;
  font-weight: 400;
  color: #807979;
  text-align: center;
  margin-top: 10px;
  font-size: 0.9em;
}
.head {
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.head img {
  width: 15px;
}
@media (max-width: 760px) {
  .card {
    padding: 10%;
  }
  .card p {
    font-size: 0.9em;
  }
  .card ul {
    padding: 2%;
  }
  .cd {
    width: 100%;
  }
  .dis_c img {
    height: 30px;
  }
  .dis_c span {
    font-size: 0.5em;
  }
  .btn_st {
    padding: 15px 25px;
  }
}
