footer {
  background-color: var(--primary);
  padding-top: 5%;
  padding-bottom: 2%;
  color: white;
}
footer {
  --font-size: 1em;
}
.cname {
  font-weight: 500;
  font-size: 1.7em;
}
.ftfx ul {
  list-style: none;
}
.ftfx .fcnt h3 {
  font-weight: 600;
  opacity: 0.5;
}
.ftfx ul li {
  margin-top: 5px;
}
.ftfx ul li:hover {
  font-weight: 600;
}
.ftfx a {
  color: white;
  font-size: var(--font-size);
}

.ftfx .fcnt {
  margin-top: 30px;
}
.note {
  text-align: center;
  font-size: var(--font-size);
  margin-bottom: 20px;
}
.copy {
  text-align: center;
  font-size: var(--font-size);
  font-weight: 600;
}
.ftfx p {
  font-size: var(--font-size);
}
.address {
  margin-bottom: 20px;
}
.address img {
  width: 20px;
  margin-right: 10px;
}
.nform {
  flex: 1;
  background-color: #ffffffe0;
  border-radius: 30px;
  overflow: hidden;
  margin-top: 10px;
}
.nform input[type="email"] {
  padding: 15px 20px;
  outline: none;
  border: none;
  background: none;
  color: var(--primary);
  font-weight: 600;
}
.nform input[type="submit"] {
  padding: 15px 30px;
  border: none;
  outline: none;
  background-color: white;
  color: var(--primary);
  cursor: pointer;
  border-radius: 30px;
  font-weight: 600;
}
.ftfx {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}
.socials {
  margin-top: 15px;
}
.socials a {
  color: white;
  padding-right: 20px;
  cursor: pointer;
}
@media (min-width: 760px) {
  .ftfx p {
    max-width: 400px;
  }
  .ftfx .fcnt {
    margin-top: 0;
  }
}

@media (max-width: 760px) {
  footer {
    --font-size: 0.9em;
  }
}
