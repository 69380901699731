.input {
  width: 100%;
  margin-top: 35px;
  position: relative;
}
.input label,
.lbtx {
  margin-bottom: 10px;
  color: var(--blue);
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
}

.input input,
.select_div {
  display: block;
  width: 100%;
  height: 40px;
  border: 2px solid var(--gray01);
  margin-top: 10px;
  outline: none;
  color: #878787;
  font-weight: 400;
  padding: 10px;
}
.input input:focus {
  border-width: 3px;
}
.pholder {
  font-size: 0.9em;
  color: #878787;
  font-weight: 400;
}
.select_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.options {
  width: 100%;
  max-height: 250px;
  box-shadow: 0px 4px 40px rgba(51, 51, 51, 0.08);
  top: 0;
  overflow-y: auto;
  position: absolute;
  background-color: var(--white);
  z-index: 10;
  top: 80px;
}
.options h2 {
  font-size: 0.9em;
  text-align: left;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 0;
}
.selected {
  display: flex;
  flex-wrap: wrap;
}
.selected div {
  cursor: pointer;
  padding: 10px;
  background-color: var(--orange01);
  border-radius: 30px;
  color: white;
  font-weight: 600;
  font-size: 0.7em;
  margin: 10px 0;
  margin-right: 10px;
}
@media (min-width: 760px) {
  .inputs .input {
    width: 48%;
  }
  .inputs {
    display: flex;
    justify-content: space-between;
  }
  .input label {
    color: #878787;
    font-weight: 400;
  }
}
