.hero {
  background: linear-gradient(
    180deg,
    #db6a04 0%,
    rgba(211, 155, 16, 0.04) 100%
  );
  width: 100vw;
  /* height: 60vh; */
  padding: 5%;
  position: relative;
  overflow-x: hidden;
}
.water_mark_catt {
  position: absolute;
  width: 80%;
  opacity: 0.1;
}
.logo_water {
  width: 20%;
  left: 30%;
  top: 30%;
}
.hero h2 {
  font-weight: 900;
  font-size: 2em;
  text-align: center;
  color: var(--white);
}
.cnt {
  margin-top: 5%;
}
.cnt .card {
  filter: drop-shadow(0px 4px 40px rgba(0, 0, 0, 0.1));
  background-color: white;
  padding: 30px;
  width: 320px;
  border-radius: 20px;
}
.li {
  margin-bottom: -5px;
}
.li img {
  width: 20px;
  margin-right: 20px;
}
.cnt .card span,
.li span {
  color: var(--gray02);
  font-weight: 400;
  display: block;
}
.it {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.pr {
  color: var(--blue) !important;
  font-weight: 900 !important;
  font-size: 2.5em;
  text-align: center;
}

.card .btn {
  margin-left: 10%;
  width: 80%;
  margin-bottom: 25px;
  font-weight: 400;
  font-size: 0.9em;
  letter-spacing: 1px;
}
.card .btn.btn-orange {
  margin-top: 20px;
}
.catt .txt {
  text-align: center;
  color: white;
  font-weight: 400;
  margin-bottom: 10px;
  width: 100%;
  font-size: 1.2em;
}
.catt {
  flex: 1;
}
.catt .imgs {
  position: relative;

  /* width: calc(100% - 300px); */
}
.catt .imgs img {
  height: 300px;
  object-fit: contain;
  position: absolute;
  top: 10vh;
}
.catt .imgs img:first-child {
  left: 15%;
}
@media (min-width: 1000px) {
  .hero {
    padding-top: 3%;
  }
  .hero h2 {
    font-size: 2.4em;
  }
}
@media (min-width: 1100px) {
  .cnt {
    padding-left: 10%;
  }
  .catt .imgs img {
    height: 400px;
    top: 7vh;
  }
}
.hk {
  margin-bottom: 40px;
  margin-left: 10%;
  margin-top: -5%;
}
.bugger {
  width: 40px;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 30px;
  /* position: fixed;
  z-index: 100;
  right: 20px; */
}
.bugger div {
  width: 100%;
  height: 5px;
  /* border-radius: 5px; */
  background-color: var(--white);
  margin-bottom: 4px;
}
.hk {
  text-align: center;
  font-size: 0.8em;
  cursor: pointer;
}
@media (min-width: 760px) and (max-width: 1000px) {
  .catt .imgs {
    margin-top: 12%;
  }
  .catt .imgs img {
    height: 230px;
  }
}
@media (max-width: 760px) {
  .hero {
    height: fit-content !important;
  }
  .hero .cnt {
    flex-direction: column-reverse;
  }
  .hero .imgs {
    margin-bottom: 20px;
  }
  .hero .imgs img {
    position: relative;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    top: 5%;
    height: 200px;
  }
  .hero .imgs img:first-child {
    margin-left: 20%;
    z-index: 2;
  }
  .hero .imgs img:last-child {
    position: absolute;
    left: -2%;
    z-index: 1;
  }
  .hero .card {
    margin-left: auto;
    margin-right: auto;
  }
  .hero h2 {
    font-size: 1.7em;
    margin-bottom: 20px;
  }
  .water_mark_catt {
    width: 100%;
    top: 15%;
    left: -10%;
  }
  .li {
    margin-bottom: 10px;
  }
}
nav {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: -100vh;
  left: 0;
  z-index: 100;
  transition: 0.3s all ease-in-out;
  display: flex;
  flex-direction: column;
  opacity: 0;
}
nav.opened {
  top: 0;
  opacity: 1;
}
nav ul {
  width: 100%;
  background-color: white;
  list-style: none;
}
nav ul li {
  padding: 20px 30px;
}
nav div {
  flex: 1;
}

@media (min-width: 760px) {
  .cnt .card .li {
    margin-bottom: 5px;
  }
}
