.share span {
  display: block;
}
.share span,
.share span strong {
  font-weight: 400;
  font-size: 1.3em;
}
.share span strong {
  font-weight: 700;
}
.share textarea {
  width: 100%;
  resize: vertical;
  height: 80px;
  margin-top: 10px;
  padding: 10px;
  border: 2px solid #eff0f2;
}
.sss {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.sss a {
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eff0f2;
  border-radius: 50%;
  text-decoration: none;
  color: var(--primary);
}
.sss a:hover {
  color: var(--orange);
}
.share {
  max-width: 700px;
  background-color: white;
  border-radius: 30px;
  margin-bottom: 30px;
  padding: 20px;
}
